import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;  // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  iconMenu: IMenuItem[] = [

    {
      name: 'MENU.PARTNERS',
      type: 'link',
      tooltip: 'Partner',
      icon: 'list',
      state: 'partners/list',
    },
    {
      name: 'MENU.CONTACTS',
      type: 'link',
      tooltip: 'Contact',
      icon: 'list',
      state: 'contacts/list',
    },
    {
      name: 'MENU.INTERESTS',
      type: 'link',
      tooltip: 'Interest',
      icon: 'list',
      state: 'projects/list/interest',
    },
    {
      name: 'MENU.CONTRACTS',
      type: 'link',
      tooltip: 'Contracts',
      icon: 'list',
      state: 'projects/list/contract',
    },
    {
      name: 'MENU.PROJECTS',
      type: 'link',
      tooltip: 'Project',
      icon: 'list',
      state: 'projects/list/project',
    },
    {
      name: 'MENU.TASKS',
      type: 'link',
      tooltip: 'Tasks',
      icon: 'list',
      state: 'tasks/mytasks',
    },
    {
      name: 'MENU.EVENTS',
      type: 'link',
      tooltip: 'Event',
      icon: 'calendar_today',
      state: 'events/calendar',
    },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  constructor() {}

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.

  // VIEW FULL VERSION TO SEE ACTUAL IMPLEMENTATION

  publishNavigationChange(menuType: string) {
    this.menuItems.next(this.iconMenu);
    // switch (menuType) {
    //   case 'separator-menu':
    //     this.menuItems.next(this.separatorMenu);
    //     break;
    //   case 'icon-menu':
    //     this.menuItems.next(this.iconMenu);
    //     break;
    //   default:
    //     this.menuItems.next(this.plainMenu);
    // }
  }
}
