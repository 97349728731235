import { Directive, Injectable, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { RoleService } from '../services/role.service';

@Directive({
  selector: '[hasRole]'
})
@Injectable({providedIn: 'root'})
export class RoleDirective {
  private hasView = false;
  constructor(private templateRef: TemplateRef<any>,private viewContainer: ViewContainerRef, private roleService: RoleService) { 
  }

  @Input() set hasRole(role: [String])
  {
    if(this.roleService.hasRole(role) && !this.hasView)
    {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
    else
    {
      this.viewContainer.clear();
      this.hasView = false;
    }
    
  }
}
