import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filesize'})
export class FileSizePipe implements PipeTransform {
  transform(size: number) : string {
    if(size < 1024)
    {
        return "1 KB";
    }
    size = size / 1024;
    if(size < 1024)
    {
        return  size.toFixed(0).toString() + " KB";
    }
    size = size / 1024;
    return size.toFixed(2).toString() + " MB";
  }
}