import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { RoutePartsService } from './shared/services/route-parts.service';

import { filter } from 'rxjs/operators';
import { UILibIconService } from './shared/services/ui-lib-icon.service';
import { ThemeService } from './shared/services/theme.service';
import { LayoutService } from './shared/services/layout.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'EPM';
  pageTitle = '';

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private iconService: UILibIconService,
    private layoutService: LayoutService,
    private translate: TranslateService,
    private cookieService: CookieService,
    
  ) {

    const browserLang: string = translate.getBrowserLang();
    if(!cookieService.check('lang') || !cookieService.get('lang').match(/en|hu/))
    {
      const lang = browserLang.match(/en|hu/) ? browserLang : 'en';
      cookieService.set('lang', lang, {expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365), path: '/', sameSite: 'Strict'});
    }
    translate.use(cookieService.get('lang'));
    translate.onLangChange.subscribe((val)=>{
      this.setTitle();
    });
    iconService.init()
  }

  ngOnInit() {
    this.changePageTitle();
    // this.themeService.applyMatTheme(this.layoutService.layoutConf.matTheme);
  }

  ngAfterViewInit() {
  }

  setTitle()
  {
    const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }
      // Extract title from parts;
      this.pageTitle = routeParts
                      .reverse()
                      .map((part) => part.title )
                      .reduce((partA, partI) => {
                        partA = this.translate.instant(partA);
                        partI = this.translate.instant(partI);
                        return `${partA} > ${partI}`
                      });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      this.setTitle()
    });


  }
}
