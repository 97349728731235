import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'events/calendar',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session'}
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
        data: { title: 'MENU.PROFILE', breadcrumb: 'PROFILE'}
      },
      {
        path: 'partners',
        loadChildren: () => import('./views/partner/partner.module').then(m => m.PartnerModule),
        data: { title: 'MENU.PARTNERS', breadcrumb: 'PARTNERS'}
      },
      {
        path: 'contacts',
        loadChildren: () => import('./views/contact/contact.module').then(m => m.ContactModule),
        data: { title: 'MENU.CONTACTS', breadcrumb: 'CONTACTS'}
      },
      {
        path: 'projects',
        loadChildren: () => import('./views/project/project.module').then(m => m.ProjectModule),
        data: { title: 'MENU.PROJECTS', breadcrumb: 'PROJECTS'}
      },
      {
        path: 'events',
        loadChildren: () => import('./views/event/event.module').then(m => m.EventModule),
        data: { title: 'MENU.EVENTS', breadcrumb: 'EVENTS'}
      },
      {
        path: 'tasks',
        loadChildren: () => import('./views/task/task.module').then(m => m.TaskModule),
        data: { title: 'MENU.TASKS', breadcrumb: 'TASKS'}
      },
      {
        path: 'search',
        loadChildren: () => import('./views/search-view/search-view.module').then(m => m.SearchViewModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

