import { Injectable } from "@angular/core";
import { JwtAuthService } from "./auth/jwt-auth.service";

@Injectable({providedIn: 'root'})
export class RoleService
{
    constructor(private jwtAuth: JwtAuthService){}

    hasRole(roles: [String])
    {
        return roles.includes(this.jwtAuth.getUser().role) || this.jwtAuth.getUser().role == "ADMIN";
    }
}